
      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    
@media (min-width: 751px) {
  .card_container {
    @apply flex-row
  }
}


@media (min-width: 751px) {
  .section_1 {
    background: linear-gradient(180deg, #FFF 0%, #F0F2F6 30%, #F0F2F6 70%, #FFF 100%);
  }
}

@media (max-width: 750px) {
  .section_2 {
    background: linear-gradient(180deg, #FFF 0%, #F0F2F6 40%, #F0F2F6 60%, #FFF 100%);  }
}

.container {
  @at-root [data-is-stick-banner-show='true'] & {
    @apply pt-[145px];
  }
  @include mobile {
    @at-root [data-is-stick-banner-show='true'] & {
      padding-top: 130.19px;
    }
  }
  @apply pt-[65px] flex flex-col self-stretch;
}

@media (min-width: 1025px) {
  .banner{
    @apply px-3xl
  }
}
